import axios from 'axios';
import qs from 'query-string';
import { isProduction, REST_URL } from '@/utils';

export const apiInstance = axios.create({
  baseURL: REST_URL,
  method: 'GET',
  responseType: 'json',
  withCredentials: true,
  timeout: isProduction() ? 10_000 : 30_000, // ms
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'bracket' }),
  },
});

apiInstance.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error),
);

import type * as React from 'react';
import type { Account, Workspace } from '@/types/auth';

export enum AuthEvent {
  Login = 'auth.login',
  Logout = 'auth.logout',
  Impersonate = 'auth.impersonate',
  SwitchWorkspace = 'auth.switchWorkspace',
  ExitImpersonation = 'auth.exitImpersonation',
  Unauthorized = 'auth.unauthorized',
  UpdateAbilities = 'auth.updateAbilities',
}

export type AuthMachineCtx = {
  user?: Account,
  workspace?: Workspace,
  workspaces?: { id: string, name: string }[],
}

export type AuthMachineEvents =
  | { type: AuthEvent.Login, username: string, password: string }
  | { type: AuthEvent.Logout }
  | { type: AuthEvent.Impersonate, email: string }
  | { type: AuthEvent.SwitchWorkspace, id: string }
  | { type: AuthEvent.ExitImpersonation }
  | { type: AuthEvent.Unauthorized }
  | { type: AuthEvent.UpdateAbilities }

export type AuthProviderContext = {
  isImpersonating: boolean,
  isAuthenticated: boolean,
  login: (email: string, password: string) => void,
  logout: () => void,
  unauthorized: () => void,
  impersonate: (email: string) => void,
  switchWorkspace: (id: string) => void,
}

type AuthProviderChildrenProps = {
  isAuthenticated: boolean,
  isImpersonating: boolean,
}

export type AuthProviderProps = {
  children: (props: AuthProviderChildrenProps) => React.ReactElement
}

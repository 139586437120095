import type * as React from 'react';
import AntdIcon from '@ant-design/icons';

export const Icon: React.FC<any> = ({ component: IconComp, ...restProps }) => (
  <AntdIcon
    component={props => (
      <IconComp
        {...props}
        {...restProps}
        color={restProps?.color || 'currentColor'}
        fill="none"
      />
    )}
  />
)

import * as React from 'react'
import { App } from 'antd';
import { useLocalStorageState } from 'ahooks';
import { createMachine, assign, fromPromise } from 'xstate';
import { createActorContext } from '@xstate/react';
import { useMutation } from '@tanstack/react-query'
import { apiInstance } from '@/services/axios'
import { WorkspaceProvider } from '@/context/WorkspaceContext';
import { UserProvider } from '@/context/UserContext';
import type { AuthMachineEvents, AuthProviderProps, AuthMachineCtx, AuthProviderContext } from './types';
import { AuthEvent } from './types';

const AuthContext = React.createContext<AuthProviderContext | undefined>(undefined)
AuthContext.displayName = 'AuthContext'

export const useAuth = (): AuthProviderContext => {
  const ctx = React.useContext(AuthContext);

  if (!ctx) {
    throw new Error('Using "useAuth" outside "AuthProvider" is not possible!');
  }

  return ctx;
}

const authMachine = createMachine({
  id: 'authentication',
  types: {} as {
    context: AuthMachineCtx,
    events: AuthMachineEvents,
  },
  context: {
    user: undefined,
    workspace: undefined,
    workspaces: [],
  },
  initial: 'idle',
  states: {
    idle: {
      on: {
        [AuthEvent.Login]: 'authenticating',
      },
    },
    unauthenticating: {
      invoke: {
        src: 'logoutAct',
        onDone: {
          target: 'idle',
          actions: ['clearUser', 'clearWorkspace', 'clearWorkspaces']
        },
        onError: {
          target: 'idle',
          actions: ['clearUser', 'clearWorkspace', 'clearWorkspaces']
        }
      }
    },
    authenticating: {
      invoke: {
        src: 'loginAct',
        // @ts-expect-error
        input: ({ event }) => ({ username: event.username, password: event.password }),
        onDone: {
          target: 'credentialsAccepted',
          actions: ['setUser', 'setWorkspace', 'setWorkspaces'],
        },
        onError: {
          target: 'idle',
          meta: {
            message: 'Invalid Credentials Provided.',
            notification: {
              key: 'auth',
              type: 'error',
              message: 'Woops..',
              description: 'Invalid Credentials Provided.',
            }
          }
        }
      },
    },
    credentialsAccepted: {
      after: {
        2000: 'authenticated'
      }
    },
    authenticated: {
      initial: 'authenticatedFully',
      on: {
        [AuthEvent.SwitchWorkspace]: '#authentication.authenticated.switchingWorkspace',
      },
      states: {
        authenticatedFully: {
          on: {
            [AuthEvent.Logout]: '#authentication.unauthenticating',
            [AuthEvent.Impersonate]: 'impersonating',
            [AuthEvent.SwitchWorkspace]: 'switchingWorkspace',
          }
        },
        switchingWorkspace: {
          invoke: {
            src: 'switchWorkspaceAct',
            // @ts-expect-error
            input: ({ event }) => ({ id: event.id }),
            onDone: {
              target: 'authenticatedFully',
              actions: ['setWorkspace'],
            },
            onError: {
              target: 'authenticatedFully',
              meta: {
                message: 'Unable to switch workspace.',
                notification: {
                  key: 'auth',
                  type: 'error',
                  message: 'Woops..',
                  description: 'Unable to switch workspace.',
                }
              }
            }
          }
        },
        impersonating: {
          invoke: {
            src: 'loadUserAct',
            onDone: {
              actions: ['setUser', 'setWorkspace'],
            },
            onError: {
              target: 'authenticatedFully',
              meta: {
                notification: {
                  key: 'auth',
                  type: 'error',
                  message: 'Woops..',
                  description: 'Unable to impersonate user.',
                }
              }
            }
          },
          on: {
            [AuthEvent.Logout]: 'authenticatedFully',
          },
        },
      },
    },
  },
});

export const AuthMachineContext = createActorContext(authMachine);

export const useWorkspaces = () => AuthMachineContext.useSelector(state => state.context.workspaces);

const useMachineStorage = () => useLocalStorageState<any>('auth', {
  serializer: JSON.stringify,
  deserializer: JSON.parse,
});

const Providers: React.FC<{ children: AuthProviderProps['children'] }> = ({ children }) => {
  const { message, notification } = App.useApp();
  const context = AuthMachineContext.useSelector(state => state.context);
  const isImpersonating = AuthMachineContext.useSelector(state => state.matches('impersonating'));
  const isAuthenticated = AuthMachineContext.useSelector(state => state.matches('authenticated'));
  const actorRef = AuthMachineContext.useActorRef();
  const [, setMachineState] = useMachineStorage();

  const login = (username: string, password: string) => actorRef.send({ type: AuthEvent.Login, username, password });
  const logout = () => actorRef.send({ type: AuthEvent.Logout });
  const unauthorized = () => actorRef.send({ type: AuthEvent.Unauthorized });
  const impersonate = (email: string) => actorRef.send({ type: AuthEvent.Impersonate, email });
  const switchWorkspace = (id: string) => actorRef.send({ type: AuthEvent.SwitchWorkspace, id });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(
    () => {
      return actorRef.subscribe(() => {
        setMachineState(actorRef.getPersistedSnapshot());
      }).unsubscribe;
    },
    []
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(
    () => {
      return actorRef.subscribe(snapshot => {
        // This doesn't work sadly...
        const meta = snapshot.getMeta();

        if (meta.message) {
          // @ts-expect-error
          message.open(meta.message);
        }

        if (meta.notification) {
          // @ts-expect-error
          notification.open(meta.notification);
        }
      }).unsubscribe;
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        isImpersonating,
        isAuthenticated,
        login,
        logout,
        unauthorized,
        impersonate,
        switchWorkspace,
      }}
    >
      <WorkspaceProvider workspace={context.workspace!}>
        <UserProvider user={context.user!}>
          {children({
            isAuthenticated,
            isImpersonating,
          })}
        </UserProvider>
      </WorkspaceProvider>
    </AuthContext.Provider>
  );
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [machineState] = useMachineStorage();
  const loginMutation = useMutation<any, unknown, { username: string, password: string }>({
    mutationFn: async ({ username, password }) => apiInstance.post('/auth/login', { username, password }),
  });
  const logoutMutation = useMutation({
    mutationFn: async () => apiInstance.post('/auth/logout')
  });
  const switchWorkspaceMutation = useMutation<any, unknown, { id: string }>({
    mutationFn: async ({ id }) => apiInstance.post(
      '/workspaces/workspace/switch',
      { id },
    ),
    onSuccess: () => {
      window.location.href = '/';
      window.location.reload();
    }
  });

  return (
    <AuthMachineContext.Provider
      options={{ state: machineState }}
      logic={authMachine.provide({
        actors: {
          loginAct: fromPromise(({ input }) => loginMutation.mutateAsync({ username: input.username, password: input.password })),
          logoutAct: fromPromise(() => logoutMutation.mutateAsync()),
          switchWorkspaceAct: fromPromise(({ input }) => switchWorkspaceMutation.mutateAsync({ id: input.id })),
          loadUserAct: fromPromise(() => Promise.resolve({})),
        },
        actions: {
          setUser: assign({
            // @ts-expect-error
            user: ({ event }) => event.output.user,
          }),
          setWorkspace: assign({
            // @ts-expect-error
            workspace: ({ event }) => event.output.workspace,
          }),
          setWorkspaces: assign({
            // @ts-expect-error
            workspaces: ({ event }) => event.output.workspaces,
          }),
          clearUser: assign({
            user: undefined,
          }),
          clearWorkspace: assign({
            workspace: undefined,
          }),
          clearWorkspaces: assign({
            workspaces: [],
          })
        }
      })}
    >
      <Providers>
        {children}
      </Providers>
    </AuthMachineContext.Provider>
  )
};

import type { MongoAbility } from '@casl/ability';

export enum AbilityRole {
  User = 'ROLE_USER',
  Finance = 'ROLE_FINANCE',
  Warehouse = 'ROLE_WAREHOUSE',
  Manager = 'ROLE_MANAGER',
  Developer = 'ROLE_DEV',
}

export enum AbilityAction {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Archive = 'archive',
  Delete = 'delete',
}

export enum AbilitySubject {
  CRM = 'crm',
  Financing = 'financing',
  Warehousing = 'warehousing',
  Reporting = 'reporting',
  Dev = 'dev',
  All = 'all',
}

export type GaurdRules = {
  action: AbilityAction;
  subject: AbilitySubject;
}

export type AppAbilityType = MongoAbility<[AbilityAction, AbilitySubject]>

export type Account = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  roles: AbilityRole[];
}

export type Workspace = {
  id: string;
  name: string;
  vatNo: string;
  cocNo: string;
  email: string;
  phoneNumber: string;
  faxNumber: string;
  website: string;
  avatarUrl: string;
  plan: string;
  location: {
    id: string;
    name: string;
    street: string;
    zip: string;
    city: string;
    state: string;
    countryName: string;
    countryISOAlpha2: string;
    countryISOAlpha3: string;
    attention: string;
  };
}

import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import DayJsRelativeTimePlugin from 'dayjs/plugin/relativeTime'
import DayJsLocalizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import DayJsTimezonePlugin from 'dayjs/plugin/timezone'
import DayJsDurationPlugin from 'dayjs/plugin/duration'
import DayJsQuarterOfYearPlugin from 'dayjs/plugin/quarterOfYear'
import DayJsAdvancedFormatPlugin from 'dayjs/plugin/advancedFormat'
import { isProduction } from '@/utils';

Sentry.init({
  enabled: isProduction(),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.zodErrorsIntegration(),
    // @see https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
    // Sentry.reactRouterV6BrowserTracingIntegration(),
  ],
});

// @todo [LOW-PRIO] someday a designer will pick a nice color pallet.
// atleast by then the codebase uses the theme variable
// G2.registerTheme('shypr', {
//   colors10: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C'],
//   colors20: ['#FACDAA', '#F4A49E', '#EE7B91', '#E85285', '#BE408C', '#BE408C', '#942D93'],
// });

dayjs.extend(DayJsTimezonePlugin)
dayjs.extend(DayJsQuarterOfYearPlugin)
dayjs.extend(DayJsRelativeTimePlugin)
dayjs.extend(DayJsLocalizedFormatPlugin)
dayjs.extend(DayJsDurationPlugin)
dayjs.extend(DayJsAdvancedFormatPlugin)
dayjs.tz.setDefault('Europe/Amsterdam')

// // @see https://github.com/storybookjs/storybook/issues/17336
// let envVariables= z.object({});
// if (!import.meta.STORYBOOK) {
//   envVariables = z.object({
//     API_ENDPOINT: z.string().url(),
//     SENTRY_DSN: z.string().url(),
//     GMAPS_KEY: z.string(),
//     MAPQUEST_KEY: z.string(),
//   });
//
//   envVariables.parse(import.meta);
// }

// declare global {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface ImportMetaEnv extends z.infer<typeof envVariables> {}
// }

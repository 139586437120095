import type * as React from 'react';

type CondWrapProps = {
  cond: boolean,
  childrenAsFallback?: boolean,
  children: React.ReactNode,
  wrapper: (children: React.ReactNode) => React.ReactNode,
  fallback?: (children: React.ReactNode) => React.ReactNode,
}

export const CondWrap: React.FC<CondWrapProps> = ({ cond, wrapper, children, fallback, childrenAsFallback = false }) => {
  if (cond) {
    return <>{wrapper(children)}</>;
  }

  if (fallback) {
    return <>{fallback(children)}</>;
  }

  if (childrenAsFallback) {
    return <>{children}</>;
  }

  return null;
}

import type * as React from 'react';
import type { PlacementProps } from './Placement.types';

const positions: Record<PlacementProps['position'], React.CSSProperties> = {
  topLeft: { placeContent: 'flex-start', placeItems: 'flex-start' },
  topCenter: { placeContent: 'center', placeItems: 'flex-start' },
  topRight: { placeContent: 'flex-end', placeItems: 'flex-start' },
  bottomLeft: { placeContent: 'flex-start', placeItems: 'flex-end' },
  bottomRight: { placeContent: 'flex-end', placeItems: 'flex-end' },
  bottomCenter: { placeContent: 'center', placeItems: 'flex-end' },
  centerCenter: { placeContent: 'center', placeItems: 'center' },
}

export const Placement: React.FC<PlacementProps> = ({
  position,
  fullContainer = false,
  children
}) => {
  if (!positions[position]) {
    throw new Error(`Invalid placement position: ${position}`);
  }

  const style: React.CSSProperties = {
    display: 'flex',
    width: fullContainer ? '100vw' : '100%',
    height: fullContainer ? '100vh' : '100%',
    ...positions[position],
  };

  return (
    <div style={style}>
      {children}
    </div>
  )
}

import type * as React from 'react'
import { Empty as ReallyEmpty } from 'antd'
import type { EmptyProps as ReallyEmptyProps } from 'antd/es/empty'
import EmptySVG from '@/static/illustrations/empty.svg'
import { Visible } from '../Visible'

type EmptyProps = {
  show?: boolean;
  description?: React.ReactNode | string;
}

export const Empty: React.FC<EmptyProps & ReallyEmptyProps> = ({ show, description, ...props }) => (
  <Visible when={show}>
    <ReallyEmpty
      {...props}
      image={EmptySVG}
      description={description}
    >
      {props.children}
    </ReallyEmpty>
  </Visible>
);

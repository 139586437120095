import type * as React from 'react';
import { Space } from 'antd';

type VisibleProps = {
  children: React.ReactNode;
  when?: boolean;
}

export const Visible: React.FC<VisibleProps> = ({ children, when = false }) => {
  if (when) {
    return (
      <Space>
        {children}
      </Space>
    )
  }

  return null
}

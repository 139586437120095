import * as R from 'ramda';
import { Big } from 'big.js';
import * as Dinero from 'dinero.js';
import { EUR, USD, GBP, type Currency } from '@dinero.js/currencies';
import type { ScaledAmount, ComparisonOperator } from '@dinero.js/core';

export type CurrencyConfig = {
  name: string;
  code: string;
  symbol: string;
  flag: string;
};

const currencies = new Map<Currency<Big>['code'], CurrencyConfig>([
  [
    USD.code,
    {
      symbol: '$',
      name: 'US Dollars',
      code: USD.code,
      flag: 'https://flagcdn.com/w80/us.webp',
    },
  ],
  [
    EUR.code,
    {
      symbol: '€',
      name: 'Euros',
      code: EUR.code,
      flag: 'https://flagcdn.com/w80/eu.webp',
    },
  ],
  [
    GBP.code,
    {
      symbol: '£',
      name: 'British Pounds',
      code: GBP.code,
      flag: 'https://flagcdn.com/w80/gb.webp',
    },
  ],
]);

export const getAvailableCurrencies = Object.fromEntries(currencies.entries());

const getCurrency = (currency: string): Currency<Big> => {
  switch (currency) {
    case 'EUR':
      return EUR;
    case 'USD':
      return USD;
    case 'GBP':
      return GBP;
  }

  throw new Error(`Unsupported currency found: ${currency}`);
};

export const getCurrencySymbol = (currency: string) => {
  if (!currencies.has(currency)) {
    throw new Error(`Unsupported currency found: ${currency}`);
  }

  return currencies.get(currency)!.symbol;
};

export const getCurrencyName = (currency: string) => {
  if (!currencies.has(currency)) {
    throw new Error(`Unsupported currency found: ${currency}`);
  }

  return currencies.get(currency)!.name;
};

export const getCurrencyFlag = (currency: string) => {
  if (!currencies.has(currency)) {
    throw new Error(`Unsupported currency found: ${currency}`);
  }

  return currencies.get(currency)!.flag;
};

const bigCalculator: Dinero.Calculator<Big> = {
  add: (a, b) => {
    let leftNumber = a;

    if (R.is(Number, leftNumber)) {
      leftNumber = new Big(leftNumber);
    }

    return leftNumber.plus(b);
  },
  compare: (a, b) => {
    let leftNumber = a;

    if (R.is(Number, leftNumber)) {
      leftNumber = new Big(leftNumber);
    }

    return leftNumber.cmp(b) as unknown as ComparisonOperator;
  },
  decrement: v => v.minus(new Big(1)),
  increment: v => v.plus(new Big(1)),
  integerDivide: (a, b) => a.div(b).round(0, Big.roundDown),
  modulo: (a, b) => {
    let leftNumber = a;

    if (R.is(Number, leftNumber)) {
      leftNumber = new Big(leftNumber);
    }

    return leftNumber.mod(b);
  },
  multiply: (a, b) => a.times(b),
  power: (a, b) => {
    let leftNumber = a;

    if (R.is(Number, leftNumber)) {
      leftNumber = new Big(leftNumber);
    }

    return leftNumber.pow(Number(b));
  },
  subtract: (a, b) => {
    let leftNumber = a;

    if (R.is(Number, leftNumber)) {
      leftNumber = new Big(leftNumber);
    }

    return leftNumber.minus(b);
  },
  zero: () => new Big(0),
};

const makeBigMoney = Dinero.createDinero<Big>({
  calculator: bigCalculator,
  formatter: {
    toString: (amount): string => (amount ? amount.toString() : ''),
    toNumber: (amount): number => (amount ? amount.toNumber() : 0),
  },
});

export const money = (
  currencyCode: string,
  amount: string | number | Dinero.Dinero<Big>,
  scale: number | Big = 2,
): Dinero.Dinero<Big> => {
  if (!R.is(String, amount) && !R.is(Number, amount)) {
    return amount;
  }

  const currency = getCurrency(currencyCode);

  return makeBigMoney({
    amount: new Big(amount),
    scale: new Big(scale),
    currency,
  });
};

export const moneyFormat = (
  dineroObject: Dinero.Dinero<Big>,
  locale = 'nl-NL',
  options: Intl.NumberFormatOptions = {},
) => {
  const transformer = ({ value, currency }) => {
    return Number(value).toLocaleString(locale, {
      ...options,
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: currency.code,
    });
  };

  return Dinero.toDecimal(dineroObject, transformer);
};

export const divideBy = (monnies: Dinero.Dinero<Big>, divider: ScaledAmount<Big> | number) => {
  let nextDivider = divider;

  // If divider is a number, convert it to a ScaledAmount
  if (R.is(Number, divider)) {
    nextDivider = { amount: new Big(divider) };
  }

  // Can't divide amount by zero or by zero
  if (Dinero.isZero(monnies) || nextDivider.amount.eq(0)) return monnies;

  const ratios = Array.from<Big>({ length: nextDivider.amount.toNumber() }).fill(Big(1));
  const [dividedAmount] = Dinero.allocate<Big>(monnies, ratios);

  return dividedAmount;
};

export const toCents = (value: string | number) => {
  const number = new Big(value);
  const [int] = number.toFixed(2).split('.');

  return number
    .times(100)
    .toFixed(0)
    .padEnd(int!.length === 1 ? 3 : 4, '0');
};

export type SubTotalCalculator = {
  currency: string;
  pricePerUnit: number;
  quantity: number;
  unitQuantity: number | string;
};

export const subTotalCalculator = ({ quantity, unitQuantity, pricePerUnit, currency }: SubTotalCalculator) => {
  try {
    const actualQuantity = new Big(quantity).div(unitQuantity);
    const monnies = money(currency, pricePerUnit);

    return Dinero.multiply(monnies, actualQuantity);
  } catch {
    // console.warn('SubTotalCalculator failed: ', props.currency, props.pricePerUnit, props.quantity, props.unitQuantity);
    return money(currency, 0);
  }
};

const locale = 'nl-NL';

export const currencyFormatter = currency => value => {
  if (!Number(value)) return '0.00';

  const amount = Number(value) / 100;

  return new Intl.NumberFormat(locale, {
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const currencyParser = val => {
  try {
    // for when the input gets cleared
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp(`\\${group}`, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
    // => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    // => 1232.21

    // rounding to two decimal places and parsing back to number as cents
    const parsedValue = Number.parseFloat(Number.parseFloat(reversedVal).toFixed(2)) * 100;

    return Number.isNaN(parsedValue) ? 0 : parsedValue;
  } catch (error) {
    console.error(error);

    return 0;
  }
};

import '@total-typescript/ts-reset';
import 'stop-runaway-react-effects/hijack';

import './setup';
import './__styles/global.css';
import './__styles/variables.css';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';

try {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App />);
} catch (e) {
  Sentry.captureException(e);
}

import * as R from 'ramda'
import type { Base64UploadFile } from '@/types/file';

export const prepareSingleUpload = (attachment: Base64UploadFile) => ({
  name: attachment.name,
  mimeType: attachment.type,
  base64: attachment.base64,
});

export const prepareMultiUpload = (attachments: Base64UploadFile[]) => R.map(prepareSingleUpload, attachments)

export const mimeTypes: Record<string, string> = {
  'image/gif': 'GIF',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/svg+xml': 'SVG',
  'image/webp': 'WEBP',
  'application/pdf': 'PDF',
  'application/msword': 'Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
  'application/gzip': 'GZIP',
  'application/zip': 'Zip',
  'application/json': 'JSON',
  'application/xml': 'XML',
  'text/xml': 'XML',
  'text/csv': 'CSV',
  'text/plain': 'TEXT',
}

export const uploadAccept = R.join(',', R.keys(mimeTypes))

export const base64Encode = (file: Blob) => new Promise<FileReader['result']>(
  (resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(
      reader.result?.toString().replace(/^data:(.*,)?/, '') ?? ''
    )
    reader.onerror = reject
  }
);

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT;
export const REST_URL = String(`${API_ENDPOINT}/rest`);
export const GQL_URL = String(`${API_ENDPOINT}/graphql`);

/**
 * @deprecated use entityToIri
 */
export const toIri = (resource: string, id: string): string => `/${resource}/${id}`;

export const restPoint = (uri: string) => {
  let newUri = uri;

  if ('/' === uri.charAt(0)) {
    newUri = uri.slice(1);
  }

  return `${REST_URL}/${newUri}`;
};

import * as React from 'react';
import { App } from 'antd';
import { useNetwork } from 'ahooks';
import { IconCloudOff, IconCloudCheck } from '@tabler/icons-react';
import { Icon } from '@/components/Icon';

export const OfflineDetector: React.FC = () => {
  const { online, since } = useNetwork();
  const { notification } = App.useApp();

  React.useEffect(() => {
    if (!online) {
      notification.info({
        key: 'network',
        icon: <Icon component={IconCloudOff} />,
        message: 'Reconnecting...',
        description: 'You will not be able to save changes until your connection is restored.',
        placement: 'bottomRight',
        duration: 0,
      });
    } else if (online && since) {
      notification.success({
        key: 'network',
        icon: <Icon component={IconCloudCheck} />,
        message: 'Connected!',
        description: 'You can work again.',
        placement: 'bottomRight',
        duration: 5,
      });
    } else {
      notification.destroy('network');
    }
  }, [notification, online, since]);

  return null;
};

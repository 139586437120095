import * as React from 'react'
import type { Workspace } from '@/types/auth'

const WorkspaceContext = React.createContext<Workspace | undefined>(undefined)
WorkspaceContext.displayName = 'WorkspaceContext'

export const useWorkspace = () => {
  const ctx = React.useContext(WorkspaceContext)

  if (!ctx) {
    throw new Error('Using "useWorkspace" outside "WorkspaceProvider" is not possible!')
  }

  return ctx
}

export const WorkspaceProvider: React.FC<{ workspace: Workspace, children: React.ReactNode }> = ({ workspace, children }) => (
  <WorkspaceContext.Provider value={workspace}>
    {children}
  </WorkspaceContext.Provider>
);

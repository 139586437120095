import type * as React from 'react'
import Logo from '@/static/slogowhite.svg?react'
import { CondWrap } from '@/components/CondWrap';
import { Placement } from '@/features/layout/Placement';
import type { LoaderProps } from './Loader.types';
import './Loader.css'

export const Loader: React.FC<LoaderProps> = ({ show, placement = 'centerCenter' }) => (
  <CondWrap
    cond={show}
    wrapper={children => <Placement position={placement}>{children}</Placement>}
  >
    <div className="container">
      <div className="loaderlogo">
        <Logo width={38} height={38} />
      </div>

      <div className="loader" style={{ left: 4, top: 0, height: 4, width: 0, animation: 'topSlide 1s linear forwards infinite' }} />
      <div className="loader" style={{ right: 0, top: 4, width: 4, height: 0, animation: 'rightSlide 1s linear forwards infinite', animationDelay: '500ms' }} />
      <div className="loader" style={{ right: 4, bottom: 0, height: 4, width: 0, animation: 'bottomSlide 1s linear forwards infinite' }} />
      <div className="loader" style={{ left: 0, bottom: 4, width: 4, height: 0, animation: 'leftSlide 1s linear forwards infinite', animationDelay: '500ms' }} />
    </div>
  </CondWrap>
)

import * as R from 'ramda'
import type { FormItemNamePath } from '@/forms/types'

export const nestPath = (path: FormItemNamePath, extraPath: FormItemNamePath): FormItemNamePath => {
  const addPath = R.ifElse(
    R.is(Array),
    R.flip(R.concat),
    R.append,
  )

  const nestPaths = R.pipe(
    // @ts-expect-error unknown error
    addPath(path),
    // @ts-expect-error unknown error
    addPath(extraPath),
  )

  // @ts-expect-error unknown error
  return nestPaths([])
}

export const getParentPath = R.when<any, any>(
  R.is(Array),
  R.dropLast(1),
)

import * as R from 'ramda'

export const isNot = R.complement(R.not)
export const isEmptyOrNil = R.either(R.isEmpty, R.isNil)
export const isNotEmptyOrNil = R.complement(isEmptyOrNil)
export const tapCatch = R.curry((fn: (error: Error) => void, error: Error) => {
  fn(error)

  throw error
})
export const capitalize = v => `${v.toString().charAt(0).toUpperCase()}${v.slice(1)}`;

export const mapIndexed = R.addIndex(R.map);

export function debugPipe<R>(value: R): R {
  console.log(value);

  return value;
}

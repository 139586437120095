import * as React from 'react';
import { createContextualCan, useAbility } from '@casl/react';
import { AuthMachineContext } from '@/context/AuthContext';
import { abilities, updateAbilities } from '@/services/security';
import type { AppAbilityType } from '@/types/auth';

const AbilityContext = React.createContext<AppAbilityType>(undefined!);
AbilityContext.displayName = 'AbilityContext';

export const Can = createContextualCan(AbilityContext.Consumer);
export const useGuard = () => useAbility<AppAbilityType>(AbilityContext);

export const AbilityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authRef = AuthMachineContext.useActorRef();

  React.useEffect(
    () => {
      return authRef.subscribe(nextState => {
        // on clear abilities
        if (nextState.matches('unauthenticating')) {
          abilities.update([]);
        }

        // on update abilities or impersonating
        if (
          nextState.matches('authenticated')
          || nextState.matches('impersonating')
        ) {
          abilities.update(updateAbilities(nextState.context.user!.roles).rules);
        }
      }).unsubscribe;
    },
    [authRef]
  );

  // On page load, we need to restore the abilities
  React.useEffect(
    () => {
      const state = authRef.getSnapshot();

      if (state.matches('authenticated')) {
        abilities.update(updateAbilities(state.context.user!.roles).rules);
      }
    },
    [authRef]
  );

  return (
    <AbilityContext.Provider value={abilities}>
      {children}
    </AbilityContext.Provider>
  );
}


import * as React from 'react'
import type { Account } from '@/types/auth'

const UserContext = React.createContext<Account | undefined>(undefined)
UserContext.displayName = 'UserContext'

export const useUser = () => {
  const ctx = React.useContext(UserContext)

  if (!ctx) {
    throw new Error('Using "useUser" outside "UserProvider" is not possible!')
  }

  return ctx
}

export const UserProvider: React.FC<{ user: Account, children: React.ReactNode }> = ({ user, children }) => (
  <UserContext.Provider value={user}>
    {children}
  </UserContext.Provider>
);

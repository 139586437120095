import * as R from 'ramda'

export function getEnv(): string | undefined {
  if (R.isEmpty(process.env.NODE_ENV)) {
    return 'development'
  }

  return process.env.NODE_ENV;
}

export const isEnv = (env: string) => R.equals(getEnv(), env);
export const isProduction = () => isEnv('production');
export const isDevelopment = () => isEnv('development');
export const isTest = () => isEnv('test');

import { AbilityBuilder, createMongoAbility, defineAbility } from '@casl/ability';
import { AbilityAction, AbilityRole, AbilitySubject, type AppAbilityType } from '@/types/auth';

export const abilities = defineAbility<AppAbilityType>(() => null);

export const updateAbilities = (roles: AbilityRole[] = []) => {
  const builder = new AbilityBuilder<AppAbilityType>(createMongoAbility);

  if (roles.length === 0) return builder.build();

  if (roles.includes(AbilityRole.Developer)) {
    builder.can(AbilityAction.Manage, AbilitySubject.All);
  }

  if (roles.includes(AbilityRole.Manager) || roles.includes(AbilityRole.User)) {
    builder.can([AbilityAction.Manage, AbilityAction.Update], AbilitySubject.CRM);
  }

  if (roles.includes(AbilityRole.Manager) || roles.includes(AbilityRole.Finance)) {
    builder.can([AbilityAction.Manage, AbilityAction.Update], AbilitySubject.Financing);
  }

  if (roles.includes(AbilityRole.Manager) || roles.includes(AbilityRole.Warehouse)) {
    builder.can([AbilityAction.Manage, AbilityAction.Update], AbilitySubject.Warehousing);
  }

  if (roles.includes(AbilityRole.Manager)) {
    builder.can([AbilityAction.Manage], AbilitySubject.Reporting);
  }

  return builder.build();
}

import type { ThemeConfig } from 'antd/es/config-provider/context'

export const theme: ThemeConfig = {
  token: {
    colorPrimary: 'hsl(211, 100%, 38%)',
    colorLink: 'hsl(217, 89%, 61%)',
    colorSuccess: 'hsl(151, 83%, 34%)',
    colorWarning: 'hsl(44, 100%, 48%)',
    colorError: 'hsl(5, 69%, 54%)',
    colorTextHeading: 'hsla(0, 0%, 0%, 0.85)',
    colorText: 'hsla(0, 0%, 0%, 0.65)',
    colorTextSecondary: 'hsla(0, 0%, 0%, 0.45)',
    colorTextDisabled: 'hsla(0, 0%, 0%, 0.25)',
    colorWhite: 'hsl(0, 100%, 100%)',
    fontSize: 16,
    fontFamily: '"Noto Sans", sans-serif',
  }
}
